/* TOASTER */
export const SET_TOASTER = "SET_TOASTER"

/* COOKIE */
export const SET_COOKIE_SETTINGS = "SET_COOKIE_SETTINGS"
export const INIT_COOKIE_SETTINGS = "INIT_COOKIE_SETTINGS"
export const TOGGLE_COOKIE_SETTINGS = "TOGGLE_COOKIE_SETTINGS"
export const TOGGLE_EXTERNAL_LOGIN_LOADING = "TOGGLE_EXTERNAL_LOGIN_LOADING"

/* SIDEBAR */
export const CHANGE_SIDEBAR = "CHANGE_SIDEBAR"

/*WEB SIDEBAR*/
export const CHANGE_WEB_SIDEBAR = "CHANGE_WEB_SIDEBAR"

/*WEB LOGIN FROM*/
export const TOGGLE_LOGIN_FROM = "TOGGLE_LOGIN_FROM"

/* LOCALE */
export const SET_LOCALE = "SET_LOCALE"

/* PRODUCT */
export const SET_PRODUCT = "SET_PRODUCT"

/* READ NOTIFICATIONS */
export const SET_NOTIFICATION_READ = "SET_NOTIFICATION_READ"

/* FILTERS */
export const SET_FILTER_DATA = "SET_FILTER_DATA"
export const TOGGLE_FILTER = "TOGGLE_FILTER"

/*PERSISTENT FILTER*/
export const SET_PERSISTENT_FILTERS = "SET_PERSISTENT_FILTERS"
export const INIT_PERSISTENT_FILTERS = "INIT_PERSISTENT_FILTERS"

/* USER AUTH */
export const REQUEST_CURRENT_USER = "REQUEST_CURRENT_USER"
export const RECEIVE_CURRENT_USER = "RECEIVE_CURRENT_USER"
export const ERROR_CURRENT_USER = "ERROR_CURRENT_USER"
export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER"

/* ADMIN AUTH */
export const REQUEST_CURRENT_ADMIN = "REQUEST_CURRENT_ADMIN"
export const RECEIVE_CURRENT_ADMIN = "RECEIVE_CURRENT_ADMIN"
export const ERROR_CURRENT_ADMIN = "ERROR_CURRENT_ADMIN"
export const INIT_CURRENT_ADMIN = "INIT_CURRENT_ADMIN"

/* INFO */
export const REQUEST_INFO = "REQUEST_INFO"
export const RECEIVE_INFO = "RECEIVE_INFO"
export const ERROR_INFO = "ERROR_INFO"

/* STRINGS */
export const REQUEST_STRINGS = "REQUEST_STRINGS"
export const RECEIVE_STRINGS = "RECEIVE_STRINGS"
export const UPDATE_STRINGS = "UPDATE_STRINGS"
export const ERROR_STRINGS = "ERROR_STRINGS"
export const TOGGLE_DEV_STRINGS = "TOGGLE_DEV_STRINGS"

/* ADMIN STRINGS */
export const REQUEST_ADMIN_STRINGS = "REQUEST_ADMIN_STRINGS"
export const RECEIVE_ADMIN_STRINGS = "RECEIVE_ADMIN_STRINGS"
export const UPDATE_ADMIN_STRINGS = "UPDATE_ADMIN_STRINGS"
export const ERROR_ADMIN_STRINGS = "ERROR_ADMIN_ROLES"

/* ADMINS */
export const REQUEST_ADMINISTRATORS = "REQUEST_ADMINISTRATORS"
export const RECEIVE_ADMINISTRATORS = "RECEIVE_ADMINISTRATORS"
export const UPDATE_ADMINISTRATORS = "UPDATE_ADMINISTRATORS"
export const ERROR_ADMINISTRATORS = "ERROR_ADMINISTRATORS"

/* ROLES */
export const REQUEST_ROLES = "REQUEST_ROLES"
export const RECEIVE_ROLES = "RECEIVE_ROLES"
export const UPDATE_ROLES = "UPDATE_ROLES"
export const ERROR_ROLES = "ERROR_ROLES"

/* PERMISSIONS */
export const REQUEST_PERMISSIONS = "REQUEST_PERMISSIONS"
export const RECEIVE_PERMISSIONS = "RECEIVE_PERMISSIONS"
export const UPDATE_PERMISSIONS = "UPDATE_PERMISSIONS"
export const ERROR_PERMISSIONS = "ERROR_PERMISSIONS"

/* ADMIN_PRODUCTS */
export const REQUEST_ADMIN_PRODUCTS = "REQUEST_ADMIN_PRODUCTS"
export const RECEIVE_ADMIN_PRODUCTS = "RECEIVE_ADMIN_PRODUCTS"
export const UPDATE_ADMIN_PRODUCTS = "UPDATE_ADMIN_PRODUCTS"
export const ERROR_ADMIN_PRODUCTS = "ERROR_ADMIN_PRODUCTS"

/* ADMIN_HELP */
export const REQUEST_ADMIN_HELP = "REQUEST_ADMIN_HELP"
export const RECEIVE_ADMIN_HELP = "RECEIVE_ADMIN_HELP"
export const UPDATE_ADMIN_HELP = "UPDATE_ADMIN_HELP"
export const ERROR_ADMIN_HELP = "ERROR_ADMIN_HELP"

/* ADMIN_CUSTOMERS */
export const REQUEST_ADMIN_CUSTOMERS = "REQUEST_ADMIN_CUSTOMERS"
export const RECEIVE_ADMIN_CUSTOMERS = "RECEIVE_ADMIN_CUSTOMERS"
export const UPDATE_ADMIN_CUSTOMERS = "UPDATE_ADMIN_CUSTOMERS"
export const ERROR_ADMIN_CUSTOMERS = "ERROR_ADMIN_CUSTOMERS"

/* ADMIN_USERS */
export const REQUEST_ADMIN_USERS = "REQUEST_ADMIN_USERS"
export const RECEIVE_ADMIN_USERS = "RECEIVE_ADMIN_USERS"
export const UPDATE_ADMIN_USERS = "UPDATE_ADMIN_USERS"
export const ERROR_ADMIN_USERS = "ERROR_ADMIN_USERS"

/* ADMIN_BLOG_CATEGORIES */
export const REQUEST_ADMIN_BLOG_CATEGORIES = "REQUEST_ADMIN_BLOG_CATEGORIES"
export const RECEIVE_ADMIN_BLOG_CATEGORIES = "RECEIVE_ADMIN_BLOG_CATEGORIES"
export const UPDATE_ADMIN_BLOG_CATEGORIES = "UPDATE_ADMIN_BLOG_CATEGORIES"
export const ERROR_ADMIN_BLOG_CATEGORIES = "ERROR_ADMIN_BLOG_CATEGORIES"

/* ADMIN_SUPPORT_CATEGORIES */
export const REQUEST_ADMIN_SUPPORT_CATEGORIES = "REQUEST_ADMIN_SUPPORT_CATEGORIES"
export const RECEIVE_ADMIN_SUPPORT_CATEGORIES = "RECEIVE_ADMIN_SUPPORT_CATEGORIES"
export const UPDATE_ADMIN_SUPPORT_CATEGORIES = "UPDATE_ADMIN_SUPPORT_CATEGORIES"
export const ERROR_ADMIN_SUPPORT_CATEGORIES = "ERROR_ADMIN_SUPPORT_CATEGORIES"

/* ADMIN_ADVICES */
export const REQUEST_ADMIN_ADVICES = "REQUEST_ADMIN_ADVICES"
export const RECEIVE_ADMIN_ADVICES = "RECEIVE_ADMIN_ADVICES"
export const UPDATE_ADMIN_ADVICES = "UPDATE_ADMIN_ADVICES"
export const ERROR_ADMIN_ADVICES = "ERROR_ADMIN_ADVICES"

/* ADMIN_ADVICES_QAA */
export const REQUEST_ADMIN_ADVICES_QAA = "REQUEST_ADMIN_ADVICES_QAA"
export const RECEIVE_ADMIN_ADVICES_QAA = "RECEIVE_ADMIN_ADVICES_QAA"
export const UPDATE_ADMIN_ADVICES_QAA = "UPDATE_ADMIN_ADVICES_QAA"
export const ERROR_ADMIN_ADVICES_QAA = "ERROR_ADMIN_ADVICES_QAA"

/* ADMIN_COOKIES */
export const REQUEST_ADMIN_COOKIES = "REQUEST_ADMIN_COOKIES"
export const RECEIVE_ADMIN_COOKIES = "RECEIVE_ADMIN_COOKIES"
export const UPDATE_ADMIN_COOKIES = "UPDATE_ADMIN_COOKIES"
export const ERROR_ADMIN_COOKIES = "ERROR_ADMIN_COOKIES"

/* ADMIN_BENEFITS */
export const REQUEST_ADMIN_BENEFITS = "REQUEST_ADMIN_BENEFITS"
export const RECEIVE_ADMIN_BENEFITS = "RECEIVE_ADMIN_BENEFITS"
export const UPDATE_ADMIN_BENEFITS = "UPDATE_ADMIN_BENEFITS"
export const ERROR_ADMIN_BENEFITS = "ERROR_ADMIN_BENEFITS"

/* ADMIN_EMAIL_TEMPLATES */
export const REQUEST_EMAIL_TEMPLATES = "REQUEST_EMAIL_TEMPLATES"
export const RECEIVE_EMAIL_TEMPLATES = "RECEIVE_EMAIL_TEMPLATES"
export const UPDATE_EMAIL_TEMPLATES = "UPDATE_EMAIL_TEMPLATES"
export const ERROR_EMAIL_TEMPLATES = "ERROR_EMAIL_TEMPLATES"

/* ADMIN_DOCUMENTS */
export const REQUEST_ADMIN_DOCUMENTS = "REQUEST_ADMIN_DOCUMENTS"
export const RECEIVE_ADMIN_DOCUMENTS = "RECEIVE_ADMIN_DOCUMENTS"
export const UPDATE_ADMIN_DOCUMENTS = "UPDATE_ADMIN_DOCUMENTS"
export const ERROR_ADMIN_DOCUMENTS = "ERROR_ADMIN_DOCUMENTS"

/* ADMIN_NOTIFICATIONS */
export const REQUEST_ADMIN_NOTIFICATIONS = "REQUEST_ADMIN_NOTIFICATIONS"
export const RECEIVE_ADMIN_NOTIFICATIONS = "RECEIVE_ADMIN_NOTIFICATIONS"
export const UPDATE_ADMIN_NOTIFICATIONS = "UPDATE_ADMIN_NOTIFICATIONS"
export const ERROR_ADMIN_NOTIFICATIONS = "ERROR_ADMIN_NOTIFICATIONS"

/* LP_MATERIALS */
export const REQUEST_LP_MATERIALS = "REQUEST_LP_MATERIALS"
export const RECEIVE_LP_MATERIALS = "RECEIVE_LP_MATERIALS"
export const UPDATE_LP_MATERIALS = "UPDATE_LP_MATERIALS"
export const ERROR_LP_MATERIALS = "ERROR_LP_MATERIALS"

/* LPS */
export const REQUEST_LPS = "REQUEST_LPS"
export const RECEIVE_LPS = "RECEIVE_LPS"
export const UPDATE_LPS = "UPDATE_LPS"
export const ERROR_LPS = "ERROR_LPS"

/* ADMIN_BLOG_ARTICLES */
export const REQUEST_ADMIN_BLOG_ARTICLES = "REQUEST_ADMIN_BLOG_ARTICLES"
export const RECEIVE_ADMIN_BLOG_ARTICLES = "RECEIVE_ADMIN_BLOG_ARTICLES"
export const UPDATE_ADMIN_BLOG_ARTICLES = "UPDATE_ADMIN_BLOG_ARTICLES"
export const ERROR_ADMIN_BLOG_ARTICLES = "ERROR_ADMIN_BLOG_ARTICLES"

/* ADMIN_BLOG_ARTICLES_COMMENTS */
export const REQUEST_ADMIN_BLOG_UNANSWERED_COMMENTS = "REQUEST_ADMIN_BLOG_UNANSWERED_COMMENTS"
export const RECEIVE_ADMIN_BLOG_UNANSWERED_COMMENTS = "RECEIVE_ADMIN_BLOG_UNANSWERED_COMMENTS"
export const RELOAD_ADMIN_BLOG_UNANSWERED_COMMENTS = "RELOAD_ADMIN_BLOG_UNANSWERED_COMMENTS"
export const ERROR_ADMIN_BLOG_UNANSWERED_COMMENTS = "ERROR_ADMIN_BLOG_UNANSWERED_COMMENTS"

/* ADMIN_BLOG_ARTICLES */
export const REQUEST_ADMIN_SUPPORT_ARTICLES = "REQUEST_ADMIN_SUPPORT_ARTICLES"
export const RECEIVE_ADMIN_SUPPORT_ARTICLES = "RECEIVE_ADMIN_SUPPORT_ARTICLES"
export const UPDATE_ADMIN_SUPPORT_ARTICLES = "UPDATE_ADMIN_SUPPORT_ARTICLES"
export const ERROR_ADMIN_SUPPORT_ARTICLES = "ERROR_ADMIN_SUPPORT_ARTICLES"

/* ADMIN_SUPPORT_ARTICLES_COMMENTS */
export const REQUEST_ADMIN_SUPPORT_UNANSWERED_COMMENTS = "REQUEST_ADMIN_SUPPORT_UNANSWERED_COMMENTS"
export const RECEIVE_ADMIN_SUPPORT_UNANSWERED_COMMENTS = "RECEIVE_ADMIN_SUPPORT_UNANSWERED_COMMENTS"
export const RELOAD_ADMIN_SUPPORT_UNANSWERED_COMMENTS = "RELOAD_ADMIN_SUPPORT_UNANSWERED_COMMENTS"
export const ERROR_ADMIN_SUPPORT_UNANSWERED_COMMENTS = "ERROR_ADMIN_SUPPORT_UNANSWERED_COMMENTS"

/* ADMIN_SMS */
export const REQUEST_ADMIN_SMS = "REQUEST_ADMIN_SMS"
export const RECEIVE_ADMIN_SMS = "RECEIVE_ADMIN_SMS"
export const ERROR_ADMIN_SMS = "ERROR_ADMIN_SMS"

/* ADMIN_SMS_LIST */
export const REQUEST_ADMIN_SMS_LIST = "REQUEST_ADMIN_SMS_LIST"
export const RECEIVE_ADMIN_SMS_LIST = "RECEIVE_ADMIN_SMS_LIST"
export const UPDATE_ADMIN_SMS_LIST = "UPDATE_ADMIN_SMS_LIST"
export const ERROR_ADMIN_SMS_LIST = "ERROR_ADMIN_SMS_LIST"

/* ADMIN_SMS_LIST_RECIPIENTS */
export const REQUEST_ADMIN_SMS_LIST_RECIPIENTS = "REQUEST_ADMIN_SMS_LIST_RECIPIENTS"
export const RECEIVE_ADMIN_SMS_LIST_RECIPIENTS = "RECEIVE_ADMIN_SMS_LIST_RECIPIENTS"
export const UPDATE_ADMIN_SMS_LIST_RECIPIENTS = "UPDATE_ADMIN_SMS_LIST_RECIPIENTS"
export const ERROR_ADMIN_SMS_LIST_RECIPIENTS = "ERROR_ADMIN_SMS_LIST_RECIPIENTS"

/* ADMIN_SMS_VOUCHERS */
export const REQUEST_ADMIN_SMS_VOUCHERS = "REQUEST_ADMIN_SMS_VOUCHERS"
export const RECEIVE_ADMIN_SMS_VOUCHERS = "RECEIVE_ADMIN_SMS_VOUCHERS"
export const UPDATE_ADMIN_SMS_VOUCHERS = "UPDATE_ADMIN_SMS_VOUCHERS"
export const ERROR_ADMIN_SMS_VOUCHERS = "ERROR_ADMIN_SMS_VOUCHERS"

/* ADMIN_INVOICES */
export const REQUEST_ADMIN_INVOICES = "REQUEST_ADMIN_INVOICES"
export const RECEIVE_ADMIN_INVOICES = "RECEIVE_ADMIN_INVOICES"
export const UPDATE_ADMIN_INVOICES = "UPDATE_ADMIN_INVOICES"
export const ERROR_ADMIN_INVOICES = "ERROR_ADMIN_INVOICES"

/* LP_ORDERS */
export const REQUEST_LP_ORDERS = "REQUEST_LP_ORDERS"
export const RECEIVE_LP_ORDERS = "RECEIVE_LP_ORDERS"
export const UPDATE_LP_ORDERS = "UPDATE_LP_ORDERS"
export const ERROR_LP_ORDERS = "ERROR_LP_ORDERS"

/* ADMIN_ENUMS */
export const REQUEST_ADMIN_ENUMS = "REQUEST_ADMIN_ENUMS"
export const RECEIVE_ADMIN_ENUMS = "RECEIVE_ADMIN_ENUMS"
export const RELOAD_ADMIN_ENUMS = "RELOAD_ADMIN_ENUMS"
export const ERROR_ADMIN_ENUMS = "ERROR_ADMIN_ENUMS"

/* MODULES */
export const REQUEST_MODULES = "REQUEST_MODULES"
export const RECEIVE_MODULES = "RECEIVE_MODULES"
export const UPDATE_MODULES = "UPDATE_MODULES"
export const ERROR_MODULES = "ERROR_MODULES"

/* FUNCTIONS */
export const REQUEST_FUNCTIONS = "REQUEST_FUNCTIONS"
export const RECEIVE_FUNCTIONS = "RECEIVE_FUNCTIONS"
export const UPDATE_FUNCTIONS = "UPDATE_FUNCTIONS"
export const ERROR_FUNCTIONS = "ERROR_FUNCTIONS"

/* EXPERTISE */
export const REQUEST_EXPERTISE = "REQUEST_EXPERTISE"
export const RECEIVE_EXPERTISE = "RECEIVE_EXPERTISE"
export const UPDATE_EXPERTISE = "UPDATE_EXPERTISE"
export const ERROR_EXPERTISE = "ERROR_EXPERTISE"

/* RSS */
export const REQUEST_RSS = "REQUEST_RSS"
export const RECEIVE_RSS = "RECEIVE_RSS"
export const UPDATE_RSS = "UPDATE_RSS"
export const ERROR_RSS = "ERROR_RSS"

/* EXIT_SCREENS */
export const REQUEST_EXIT_SCREENS = "REQUEST_EXIT_SCREENS"
export const RECEIVE_EXIT_SCREENS = "RECEIVE_EXIT_SCREENS"
export const UPDATE_EXIT_SCREENS = "UPDATE_EXIT_SCREENS"
export const ERROR_EXIT_SCREENS = "ERROR_EXIT_SCREENS"

/* ENUMS */
export const REQUEST_ENUMS = "REQUEST_ENUMS"
export const RECEIVE_ENUMS = "RECEIVE_ENUMS"
export const ERROR_ENUMS = "ERROR_ENUMS"

/* LAZY_ENUMS */
export const REQUEST_LAZY_ENUMS = "REQUEST_LAZY_ENUMS"
export const RECEIVE_LAZY_ENUMS = "RECEIVE_LAZY_ENUMS"
export const ERROR_LAZY_ENUMS = "ERROR_LAZY_ENUMS"

/* ITEM */
export const REQUEST_ITEM = "REQUEST_ITEM"
export const RECEIVE_ITEM = "RECEIVE_ITEM"
export const UPDATE_ITEM = "UPDATE_ITEM"
export const ERROR_ITEM = "ERROR_ITEM"

/* SEQUENCE */
export const REQUEST_SEQUENCE = "REQUEST_SEQUENCE"
export const RECEIVE_SEQUENCE = "RECEIVE_SEQUENCE"
export const ERROR_SEQUENCE = "ERROR_SEQUENCE"

/* DOCUMENT */
export const REQUEST_DOCUMENT = "REQUEST_DOCUMENT"
export const RECEIVE_DOCUMENT = "RECEIVE_DOCUMENT"
export const ERROR_DOCUMENT = "ERROR_DOCUMENT"

/* BLOG_ITEM */
export const REQUEST_BLOG_ARTICLE = "REQUEST_BLOG_ARTICLE"
export const RECEIVE_BLOG_ARTICLE = "RECEIVE_BLOG_ARTICLE"
export const UPDATE_BLOG_ARTICLE = "UPDATE_BLOG_ARTICLE"
export const ERROR_BLOG_ARTICLE = "ERROR_BLOG_ARTICLE"
export const RELOAD_BLOG_ARTICLE = "RELOAD_BLOG_ARTICLE"

/* BLOG_LIST */
export const REQUEST_BLOG_LIST = "REQUEST_BLOG_LIST"
export const RECEIVE_BLOG_LIST = "RECEIVE_BLOG_LIST"
export const UPDATE_BLOG_LIST = "UPDATE_BLOG_LIST"
export const RELOAD_BLOG_LIST = "RELOAD_BLOG_LIST"
export const ERROR_BLOG_LIST = "ERROR_BLOG_LIST"

/* SUPPORT_ITEM */
export const REQUEST_SUPPORT_ARTICLE = "REQUEST_SUPPORT_ARTICLE"
export const RECEIVE_SUPPORT_ARTICLE = "RECEIVE_SUPPORT_ARTICLE"
export const UPDATE_SUPPORT_ARTICLE = "UPDATE_SUPPORT_ARTICLE"
export const ERROR_SUPPORT_ARTICLE = "ERROR_SUPPORT_ARTICLE"
export const RELOAD_SUPPORT_ARTICLE = "RELOAD_SUPPORT_ARTICLE"

/* SUPPORT_LIST */
export const REQUEST_SUPPORT_LIST = "REQUEST_SUPPORT_LIST"
export const RECEIVE_SUPPORT_LIST = "RECEIVE_SUPPORT_LIST"
export const UPDATE_SUPPORT_LIST = "UPDATE_SUPPORT_LIST"
export const RELOAD_SUPPORT_LIST = "RELOAD_SUPPORT_LIST"
export const ERROR_SUPPORT_LIST = "ERROR_SUPPORT_LIST"

/* AUTHOR */
export const REQUEST_AUTHOR = "REQUEST_AUTHOR"
export const RECEIVE_AUTHOR = "RECEIVE_AUTHOR"
export const ERROR_AUTHOR = "ERROR_AUTHOR"

/* AUTHOR_LIST */
export const REQUEST_AUTHORS_LIST = "REQUEST_AUTHORS_LIST"
export const RECEIVE_AUTHORS_LIST = "RECEIVE_AUTHORS_LIST"
export const ERROR_AUTHORS_LIST = "ERROR_AUTHORS_LIST"

/* NOTIFICATIONS */
export const REQUEST_NOTIFICATIONS = "REQUEST_NOTIFICATIONS"
export const RECEIVE_NOTIFICATIONS = "RECEIVE_NOTIFICATIONS"
export const ERROR_NOTIFICATIONS = "ERROR_NOTIFICATIONS"

/* FOOTER_DOCUMENTS */
export const REQUEST_FOOTER_DOCUMENTS = "REQUEST_FOOTER_DOCUMENTS"
export const RECEIVE_FOOTER_DOCUMENTS = "RECEIVE_FOOTER_DOCUMENTS"
export const ERROR_FOOTER_DOCUMENTS = "ERROR_FOOTER_DOCUMENTS"

/* BLOG_CATEGORIES */
export const REQUEST_BLOG_CATEGORIES = "REQUEST_BLOG_CATEGORIES"
export const RECEIVE_BLOG_CATEGORIES = "RECEIVE_BLOG_CATEGORIES"
export const ERROR_BLOG_CATEGORIES = "ERROR_BLOG_CATEGORIES"

/* SUPPORT_CATEGORIES */
export const REQUEST_SUPPORT_CATEGORIES = "REQUEST_SUPPORT_CATEGORIES"
export const RECEIVE_SUPPORT_CATEGORIES = "RECEIVE_SUPPORT_CATEGORIES"
export const ERROR_SUPPORT_CATEGORIES = "ERROR_SUPPORT_CATEGORIES"

/* PRODUCTS */
export const REQUEST_PRODUCTS = "REQUEST_PRODUCTS"
export const RECEIVE_PRODUCTS = "RECEIVE_PRODUCTS"
export const ERROR_PRODUCTS = "ERROR_PRODUCTS"

/* ADVICES_LIST */
export const REQUEST_ADVICES_LIST = "REQUEST_ADVICES_LIST"
export const RECEIVE_ADVICES_LIST = "RECEIVE_ADVICES_LIST"
export const ERROR_ADVICES_LIST = "ERROR_ADVICES_LIST"

/* ADVICES_QAAs */
export const REQUEST_ADVICE_QAA = "REQUEST_ADVICE_QAA"
export const RECEIVE_ADVICE_QAA = "RECEIVE_ADVICE_QAA"
export const UPDATE_ADVICE_QAA = "UPDATE_ADVICE_QAA"
export const ERROR_ADVICE_QAA = "ERROR_ADVICE_QAA"

/* COOKIES_LIST */
export const REQUEST_COOKIES = "REQUEST_COOKIES"
export const RECEIVE_COOKIES = "RECEIVE_COOKIES"
export const ERROR_COOKIES = "ERROR_COOKIES"

/* BENEFITS_LIST */
export const REQUEST_BENEFITS = "REQUEST_BENEFITS"
export const RECEIVE_BENEFITS = "RECEIVE_BENEFITS"
export const UPDATE_BENEFITS = "UPDATE_BENEFITS"
export const ERROR_BENEFITS = "ERROR_BENEFITS"

/* SMS */
export const REQUEST_SMS = "REQUEST_SMS"
export const RECEIVE_SMS = "RECEIVE_SMS"
export const ERROR_SMS = "ERROR_SMS"

/* INVOICES */
export const REQUEST_INVOICES = "REQUEST_INVOICES"
export const RECEIVE_INVOICES = "RECEIVE_INVOICES"
export const ERROR_INVOICES = "ERROR_INVOICES"

/* HELP */
export const REQUEST_HELP = "REQUEST_HELP"
export const RECEIVE_HELP = "RECEIVE_HELP"
export const ERROR_HELP = "ERROR_HELP"
