export const fetchUrl = process.env.NODE_ENV === 'production' ? '/api/w/' : 'http://cgm.cz.local/api/w/'
export const fetchAdminUrl = process.env.NODE_ENV === 'production' ? '/api/admin/' : 'http://cgm.cz.local/api/admin/'
export const mediaUrl = process.env.NODE_ENV === 'production' ? '/media/' : 'http://cgm.cz.local/api/'
export const fileUrl = process.env.NODE_ENV === 'production' ? '/documents/' : 'http://cgm.cz.local/api/'
export const ckFinderUrl = process.env.NODE_ENV === 'production' ? '/api/ckfinder/' : 'http://cgm.cz.local/api/ckfinder/'

export const routes = {
    landing: '/',
    not_found: '/404',
    err: '/500',
    admin_crossroad: '/admin-crossroad',
    user_crossroad: '/crossroad',

    login: {
        cs: '/prihlaseni',
        sk: '/prihlasenie'
    },
    registration: {
        cs: '/registrace',
        sk: '/registracia'
    },
    change_password: {
        cs: '/profil/zmena-hesla',
        sk: '/profil/zmena-hesla'
    },
    forgotten_password: {
        cs: '/obnova-zapomenuteho-hesla',
        sk: '/obnova-zabudnuteho-hesla'
    },
    set_password: {
        cs: '/nastaveni-noveho-hesla',
        sk: '/nastavenie-noveho-hesla'
    },
    userprofile: {
        cs: '/profil',
        sk: '/profil'
    },
    userprofile_delete: {
        cs: '/profil/smazat',
        sk: '/profil/zmazat'
    },
    userprofile_settings: {
        cs: '/profil/nastaveni',
        sk: '/profil/nastaveni'
    },

    landing_pages: {
        cs: '/objednavky',
        sk: '/objednavky'
    },

    documents: {
        cs: '/dokumenty',
        sk: '/dokumenty'
    },

    contact: {
        cs: '/kontakty',
        sk: '/kontakty'
    },

    order_completed: {
        cs: '/objednavka',
        sk: '/objednavka'
    },

    identification_number_change: {
        cs: '/zmena-ico',
        sk: '/zmena-ico'
    },

    blog: {
        cs: '/blog',
        sk: '/blog'
    },
    blog_category: {
        cs: '/blog/kategorie',
        sk: '/blog/kategorie'
    },
    blog_favourite: {
        cs: '/blog/oblibene',
        sk: '/blog/oblubene'
    },

    author: {
        cs: '/autor',
        sk: '/autor'
    },

    support: {
        cs: '/podpora',
        sk: '/podpora'
    },

    advice: {
        cs: '/poradna',
        sk: '/poradna'
    },

    products: {
        cs: '/produkty',
        sk: '/produkty'
    },

    services: {
        cs: '/sluzby',
        sk: '/sluzby'
    },
    services_sms: {
        cs: '/sluzby/cgmesky',
        sk: '/sluzby/cgmesky'
    },
    services_timestamps: {
        cs: '/sluzby/casova-razitka',
        sk: '/sluzby/casove-peciatky'
    },
    services_benefits: {
        cs: '/sluzby/bonusove-programy',
        sk: '/sluzby/bonusove-programy'
    },

    search: {
        cs: '/vyhledavani',
        sk: '/vyhladavanie'
    },

    admin: {
        cs: '/administrace',
        sk: '/administracia'
    },

    admin_err: {
        cs: '/administrace/500',
        sk: '/administracia/500'
    },
    admin_login: {
        cs: '/administrace/prihlaseni',
        sk: '/administracia/prihlasenie'
    },
    admin_logout: {
        cs: '/administrace/odhlaseni',
        sk: '/administracia/odhlasenie'
    },
    admin_profile: {
        cs: '/administrace/profil-spravce',
        sk: '/administracia/profil-spravca'
    },

    admin_admin: {
        cs: '/administrace/sprava',
        sk: '/administracia/sprava'
    },
    admin_info: {
        cs: '/administrace/sprava/zakladni-informace',
        sk: '/administracia/sprava/zakladne-informacie',
        hp: true
    },
    admin_blog: {
        cs: '/administrace/sprava/blog',
        sk: '/administracia/sprava/blog'
    },
    admin_blog_articles: {
        cs: '/administrace/sprava/blog/clanky',
        sk: '/administracia/sprava/blog/clanky',
        hp: true
    },
    admin_blog_articles_statistics: {
        cs: '/administrace/sprava/blog/clanky/:id/statistika',
        sk: '/administracia/sprava/blog/clanky/:id/statistika'
    },
    admin_blog_articles_comments: {
        cs: '/administrace/sprava/blog/clanky/:id/komentare',
        sk: '/administracia/sprava/blog/clanky/:id/komentare'
    },
    admin_blog_unanswered_comments: {
        cs: '/administrace/sprava/blog/nezodpovezene-komentare',
        sk: '/administracia/sprava/blog/nezodpovedane-komentare',
        hp: true
    },
    admin_support: {
        cs: '/administrace/sprava/podpora',
        sk: '/administracia/sprava/podpora'
    },
    admin_support_articles: {
        cs: '/administrace/sprava/podpora/clanky',
        sk: '/administracia/sprava/podpora/clanky',
        hp: true
    },
    admin_support_articles_statistics: {
        cs: '/administrace/sprava/podpora/clanky/:id/statistika',
        sk: '/administracia/sprava/podpora/clanky/:id/statistika'
    },
    admin_support_articles_comments: {
        cs: '/administrace/sprava/podpora/clanky/:id/komentare',
        sk: '/administracia/sprava/podpora/clanky/:id/komentare'
    },
    admin_support_unanswered_comments: {
        cs: '/administrace/sprava/podpora/nezodpovezene-komentare',
        sk: '/administracia/sprava/podpora/nezodpovedane-komentare',
        hp: true
    },
    admin_advices: {
        cs: '/administrace/sprava/poradny',
        sk: '/administracia/sprava/poradny',
        hp: true
    },
    admin_advices_qaa: {
        cs: '/administrace/sprava/poradny/otazky-a-odpovedi',
        sk: '/administracia/sprava/poradny/otazky-a-odpovedi',
        hp: true
    },
    admin_ais_info_channels: {
        cs: '/administrace/sprava/ais-info-kanaly',
        sk: '/administracia/sprava/ais-info-kanaly',
        hp: true
    },
    admin_ais_info_channels_rss: {
        cs: '/administrace/sprava/ais-info-kanaly/rss',
        sk: '/administracia/sprava/ais-info-kanaly/rss',
        hp: true
    },
    admin_ais_info_channels_exit_screens: {
        cs: '/administrace/sprava/ais-info-kanaly/ukoncovaci-obrazovky',
        sk: '/administracia/sprava/ais-info-kanaly/ukoncovacie-obrazovky',
        hp: true
    },
    admin_ais_info_channels_sms: {
        cs: '/administrace/sprava/ais-info-kanaly/hromadne-sms',
        sk: '/administracia/sprava/ais-info-kanaly/hromadne-sms',
        hp: true
    },
    admin_documents: {
        cs: '/administrace/sprava/dokumenty',
        sk: '/administracia/sprava/dokumenty',
        hp: true
    },
    admin_notifications: {
        cs: '/administrace/sprava/notifikace',
        sk: '/administracia/sprava/notifikacie',
        hp: true
    },
    admin_landing: {
        cs: '/administrace/sprava/landing-pages',
        sk: '/administracia/sprava/landing-pages',
        hp: true
    },
    admin_landing_landing: {
        cs: '/administrace/sprava/landing-pages/landing-pages',
        sk: '/administracia/sprava/landing-pages/landing-pages',
        hp: true
    },
    admin_landing_materials: {
        cs: '/administrace/sprava/landing-pages/materiály',
        sk: '/administracia/sprava/landing-pages/materialy',
        hp: true
    },
    admin_customers: {
        cs: '/administrace/sprava/zakaznici',
        sk: '/administracia/sprava/zakaznici',
        hp: true
    },
    admin_customers_customers: {
        cs: '/administrace/sprava/zakaznici/zakaznici',
        sk: '/administracia/sprava/zakaznici/zakaznici',
        hp: true
    },
    admin_customers_users: {
        cs: '/administrace/sprava/zakaznici/uzivatele',
        sk: '/administracia/sprava/zakaznici/uzivatelia',
        hp: true
    },
    admin_customers_users_statistics: {
        cs: '/administrace/sprava/zakaznici/uzivatele/:id/statistika',
        sk: '/administracia/sprava/zakaznici/uzivatelia/:id/statistika'
    },
    admin_management: {
        cs: '/administrace/sprava/sprava-systemu',
        sk: '/administracia/sprava/sprava-systemu'
    },
    admin_management_administrators: {
        cs: '/administrace/sprava/sprava-systemu/spravci',
        sk: '/administracia/sprava/sprava-systemu/spravcovia',
        hp: true
    },
    admin_management_roles: {
        cs: '/administrace/sprava/sprava-systemu/role',
        sk: '/administracia/sprava/sprava-systemu/role',
        hp: true
    },
    admin_management_permissions: {
        cs: '/administrace/sprava/sprava-systemu/opravneni',
        sk: '/administracia/sprava/sprava-systemu/opravnenia',
        hp: true
    },
    admin_enums: {
        cs: '/administrace/sprava/ciselniky',
        sk: '/administracia/sprava/ciselniky'
    },
    admin_enums_blog_categories: {
        cs: '/administrace/sprava/ciselniky/kategorie-blog',
        sk: '/administracia/sprava/ciselniky/kategorie-blog',
        hp: true
    },
    admin_enums_support_categories: {
        cs: '/administrace/sprava/ciselniky/kategorie-podpora',
        sk: '/administracia/sprava/ciselniky/kategorie-podpora',
        hp: true
    },
    admin_enums_advices: {
        cs: '/administrace/sprava/ciselniky/poradny',
        sk: '/administracia/sprava/ciselniky/poradny',
        hp: true
    },
    admin_enums_products: {
        cs: '/administrace/sprava/ciselniky/produkty',
        sk: '/administracia/sprava/ciselniky/produkty',
        hp: true
    },
    admin_enums_benefits: {
        cs: '/administrace/sprava/ciselniky/bonusove-programy',
        sk: '/administracia/sprava/ciselniky/bonusove-programy',
        hp: true
    },
    admin_enums_email_templates: {
        cs: '/administrace/sprava/ciselniky/emailove-sablony',
        sk: '/administracia/sprava/ciselniky/emailove-sablony',
        hp: true
    },
    admin_enums_cookies: {
        cs: '/administrace/sprava/ciselniky/cookies',
        sk: '/administracia/sprava/ciselniky/cookies',
        hp: true
    },
    admin_enums_sms: {
        cs: '/administrace/sprava/ciselniky/sms',
        sk: '/administracia/sprava/ciselniky/sms',
        hp: true
    },
    admin_enums_modules: {
        cs: '/administrace/sprava/ciselniky/moduly',
        sk: '/administracia/sprava/ciselniky/moduly',
        hp: true
    },
    admin_enums_functions: {
        cs: '/administrace/sprava/ciselniky/funkce',
        sk: '/administracia/sprava/ciselniky/funkcie',
        hp: true
    },
    admin_enums_expertise: {
        cs: '/administrace/sprava/ciselniky/odbornosti',
        sk: '/administracia/sprava/ciselniky/odbornosti',
        hp: true
    },
    admin_invoicing: {
        cs: '/administrace/sprava/fakturace',
        sk: '/administracia/sprava/fakturacia',
        hp: true
    },
    admin_invoicing_invoices: {
        cs: '/administrace/sprava/fakturace/faktury',
        sk: '/administracia/sprava/fakturacia/faktury',
        hp: true
    },
    admin_invoicing_sms_vouchers: {
        cs: '/administrace/sprava/fakturace/sms-vouchery',
        sk: '/administracia/sprava/fakturacia/sms-vouchery',
        hp: true
    },
    admin_invoicing_lp_orders: {
        cs: '/administrace/sprava/fakturace/landing-pages-objednavky',
        sk: '/administracia/sprava/fakturacia/landing-pages-objednavky',
        hp: true
    },
    admin_strings: {
        cs: '/administrace/sprava/retezce',
        sk: '/administracia/sprava/retazce',
        hp: true
    },
    admin_help: {
        cs: '/administrace/sprava/napoveda',
        sk: '/administracia/sprava/napoveda',
        hp: true
    },

}

export const routePermissions = {
    admin: {
        permissions: []
    },
    admin_management: {
        permissions: ['role.view', 'permission.view', 'administrator.view']
    },
    admin_management_administrators: {
        permissions: ['administrator.view']
    },
    admin_management_roles: {
        permissions: ['role.view']
    },
    admin_management_permissions: {
        permissions: ['permission.view']
    },
    admin_strings: {
        permissions: ['string.view']
    },
    admin_users: {
        permissions: ['user.view']
    },
    admin_blog: {
        permissions: ['blog.view']
    },
    admin_blog_articles: {
        permissions: ['blog.view']
    },
    admin_blog_articles_statistics: {
        permissions: ['blog.view']
    },
    admin_blog_articles_comments: {
        permissions: ['blog.view']
    },
    admin_blog_unanswered_comments: {
        permissions: ['blog.view']
    },
    admin_support: {
        permissions: ['support.view']
    },
    admin_support_articles: {
        permissions: ['support.view']
    },
    admin_support_articles_statistics: {
        permissions: ['support.view']
    },
    admin_support_articles_comments: {
        permissions: ['support.view']
    },
    admin_support_unanswered_comments: {
        permissions: ['support.view']
    },
    admin_customers: {
        permissions: ['customer.view', 'user.view']
    },
    admin_customers_customers: {
        permissions: ['customer.view']
    },
    admin_customers_users: {
        permissions: ['user.view']
    },
    admin_admin: {
        permissions: ['advice-qaa.view']
    },
    admin_advices_qaa: {
        permissions: ['advice-qaa.view']
    },
    admin_enums: {
        permissions: ['category.view', 'product.view', 'cookie.view']
    },
    admin_enums_blog_categories: {
        permissions: ['category.view']
    },
    admin_enums_support_categories: {
        permissions: ['category.view']
    },
    admin_enums_advices: {
        permissions: ['advice.view']
    },
    admin_enums_products: {
        permissions: ['product.view']
    },
    admin_enums_benefits: {
        permissions: ['benefit.view']
    },
    admin_enums_email_templates: {
        permissions: ['email-template.view']
    },
    admin_enums_cookies: {
        permissions: ['cookie.view']
    },
    admin_enums_sms: {
        permissions: ['sms.view']
    },
    admin_enums_modules: {
        permissions: ['module.view']
    },
    admin_enums_functions: {
        permissions: ['function.view']
    },
    admin_enums_expertise: {
        permissions: ['expertise.view']
    },
    admin_info: {
        permissions: ['info.view']
    },
    admin_documents: {
        permissions: ['document.view']
    },
    admin_help: {
        permissions: ['help.view']
    },
    admin_notifications: {
        permissions: ['notification.view']
    },
    admin_landing: {
        permissions: ['lp.view']
    },
    admin_landing_landing: {
        permissions: ['lp.view']
    },
    admin_landing_materials: {
        permissions: ['lp.view']
    },
    admin_invoicing: {
        permissions: ['invoice.view', 'voucher.view']
    },
    admin_invoicing_invoices: {
        permissions: ['invoice.view']
    },
    admin_invoicing_sms_vouchers: {
        permissions: ['voucher.view']
    },
    admin_invoicing_lp_orders: {
        permissions: ['invoice.view']
    },
    admin_ais_info_channels: {
        permissions: ['rss.view', 'exit-screen.view', 'admin-sms.view']
    },
    admin_ais_info_channels_rss: {
        permissions: ['rss.view']
    },
    admin_ais_info_channels_exit_screens: {
        permissions: ['exit-screen.view']
    },
    admin_ais_info_channels_sms: {
        permissions: ['admin-sms.view']
    },

    user: {
        permissions: []
    },
    userProfile: {
        permissions: []
    },
}

export const enumsFetchRoutes = {
    stringCategories: '/string-categories',
    permissions: '/permissions',
    permActions: '/permission-actions',
    roles: '/roles',
    products: '/products',
    blogCategories: '/blog-categories',
    blogArticles: '/blog-articles',
    supportCategories: '/support-categories',
    advices: '/advices',
    administrators: '/administrators',
    keywords: '/keywords',
    customers: '/customers',
    regions: '/regions',
    jobTitles: '/job-titles',
    organizationTitles: '/organization-titles',
    modules: '/modules',
    functions: '/functions',
    expertise: '/expertise',
    smsVouchers: '/sms-vouchers',
    paymentMethods: '/payment-methods',
    help: '/help',
    lpMaterials: '/lp-materials',
    users: '/users',
}

export const toasterDuration = 2500

export const loadDataRefresh = {
    default: 1000 * 60 * 2,
    //default: 10000,
    itemTabData: 1000 * 60 * 1,
    frontendOften: 1000 * 60 * 2.5,
    frontendNormal: 1000 * 60 * 5,
    frontendNever: 1000 * 60 * 10,
}